<template>
    <v-card
        class="mb-12"
        color="grey lighten-1"
        height="500px"
        >
        <gmap-map ref="gmap" :center="mapOptions.center"
            :zoom="zoom"
            mapTypeId= 'hybrid'
                :options="{
                    scrollwheel: mapOptions.scrollwheel,
                    zoomControl: mapOptions.zoomControl,
                    mapTypeControl: mapOptions.mapTypeControl,
                    streetViewControl: false,
                    scaleControl: mapOptions.scaleControl,
                    fullscreenControl: mapOptions.fullscreenControl
                }"
            style="width: 100%; height: 500px"
        >

            <gmap-polygon 
                :paths="mapPath"                 
                :options="polyOptions"
                @paths_changed="updateEdited($event, item.id)"
                ref="polygon">
            </gmap-polygon>

        </gmap-map>                    
    </v-card>   
    
</template>

<script>
import { getGoogleMapsAPI  } from 'gmap-vue';

export default {
    props:{
        item: {
            required: false,
            type: Object,
            default: () => {
                return  {
                    area_ha_calculated: true,
                    area_ha: null,
                    polygon:[],   
                }
            }
        },
        state: {
            required: false,
            type: Object,
            default: () => {
                return  {
                    state:{
                            valid:false
                        }
                    // isEdit: false
                }
            }
        }
    },

    data() {
        return {
            // valid: false,  
            mapOptions:{
                center: {lat:  47.997791, lng: 7.842609},
                scrollwheel: true,
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: true,
                fullscreenControl: true,
            },
            polyOptions:{
                        editable: false,
                        fillColor: 'red',
                        fillOpacity: 0.3,
                        strokeColor: 'red',
                        strokeOpacity: 0.8,
                        strokeWeight: 3
            },
            holeOptions:{
                        editable: true,
                        fillColor: 'grey',
                        fillOpacity: 0.6,
                        strokeColor: 'grey',
                        strokeOpacity: 0.8,
                        strokeWeight: 3
            },            
            txtSearchPos: '',
            calcArea: '',
            errMessage:'',
            cancelDrawingShape:false,
            drawingMode:null
                   
        }

    },

    computed:{   

        google: getGoogleMapsAPI,



        zoom(){
            return  this.polyAvailable ? 17 : 17;
        },

        polyAvailable(){
            return this.item.polygon ? this.item.polygon.length > 0 : false;
        },


        polyHoleAvailable(){    
            return this.item.polygon ? this.item.polygon.length > 1 : false;
        },


        mapPath(){           
            const res =  [this.polyAvailable ? this.item.polygon[0]:[], !this.polyAvailable || this.item.polygon.length < 2 ? [] : this.item.polygon[1]]
            return res
        }

    },

    methods:{
 
    },

    watch: { 
  
        item: function(newVal) { // watch it
            console.log('Prop changed: item', newVal)
            if(this.item.center)
                this.mapOptions.center = this.item.center;
        },
 
        valid: function(newVal) { // watch it
            console.log('Prop changed: valid', newVal)
            this.state.valid = this.valid; 
        }, 


    },

    mounted() {
        console.debug('mounted', this.item);
        if(this.item.center)
                this.mapOptions.center = this.item.center;

        
    }
}
</script>
