var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mb-12",attrs:{"color":"grey lighten-1","height":"500px"}},[_c('gmap-map',{ref:"gmap",staticStyle:{"width":"100%","height":"500px"},attrs:{"center":_vm.mapOptions.center,"zoom":_vm.zoom,"mapTypeId":"hybrid","options":{
                scrollwheel: _vm.mapOptions.scrollwheel,
                zoomControl: _vm.mapOptions.zoomControl,
                mapTypeControl: _vm.mapOptions.mapTypeControl,
                streetViewControl: false,
                scaleControl: _vm.mapOptions.scaleControl,
                fullscreenControl: _vm.mapOptions.fullscreenControl
            }}},[_c('gmap-polygon',{ref:"polygon",attrs:{"paths":_vm.mapPath,"options":_vm.polyOptions},on:{"paths_changed":function($event){return _vm.updateEdited($event, _vm.item.id)}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }